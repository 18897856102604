exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acosaustro-seguro-hogar-js": () => import("./../../../src/pages/acosaustro/seguro-hogar.js" /* webpackChunkName: "component---src-pages-acosaustro-seguro-hogar-js" */),
  "component---src-pages-acosaustro-seguro-salud-js": () => import("./../../../src/pages/acosaustro/seguro-salud.js" /* webpackChunkName: "component---src-pages-acosaustro-seguro-salud-js" */),
  "component---src-pages-acosaustro-seguro-vehicular-js": () => import("./../../../src/pages/acosaustro/seguro-vehicular.js" /* webpackChunkName: "component---src-pages-acosaustro-seguro-vehicular-js" */),
  "component---src-pages-acosaustro-seguro-vida-ahorro-js": () => import("./../../../src/pages/acosaustro/seguro-vida-ahorro.js" /* webpackChunkName: "component---src-pages-acosaustro-seguro-vida-ahorro-js" */),
  "component---src-pages-acosaustro-seguros-complementarios-js": () => import("./../../../src/pages/acosaustro/seguros-complementarios.js" /* webpackChunkName: "component---src-pages-acosaustro-seguros-complementarios-js" */),
  "component---src-pages-gestionsegura-seguro-digital-js": () => import("./../../../src/pages/gestionsegura/seguro-digital.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-digital-js" */),
  "component---src-pages-gestionsegura-seguro-empresarial-js": () => import("./../../../src/pages/gestionsegura/seguro-empresarial.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-empresarial-js" */),
  "component---src-pages-gestionsegura-seguro-hogar-js": () => import("./../../../src/pages/gestionsegura/seguro-hogar.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-hogar-js" */),
  "component---src-pages-gestionsegura-seguro-salud-js": () => import("./../../../src/pages/gestionsegura/seguro-salud.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-salud-js" */),
  "component---src-pages-gestionsegura-seguro-vehicular-js": () => import("./../../../src/pages/gestionsegura/seguro-vehicular.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-vehicular-js" */),
  "component---src-pages-gestionsegura-seguro-viajes-js": () => import("./../../../src/pages/gestionsegura/seguro-viajes.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-viajes-js" */),
  "component---src-pages-gestionsegura-seguro-vida-js": () => import("./../../../src/pages/gestionsegura/seguro-vida.js" /* webpackChunkName: "component---src-pages-gestionsegura-seguro-vida-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

